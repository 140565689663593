import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { images } from 'common/utils';
import { type UploadedFileDataObject } from 'models/service';
import { type AutocompleteUser, type ReduxUser } from 'models/user';
import { Button, Avatar, Form, RichTextInput, message, useRequestMentions } from 'ui';
import { AttachmentWrapper } from './AttachmentWrapper';
import { Files } from './Files';

export type CommentFormValues = {
  textContent: string;
  htmlContent: string;
  // TODO this is not correct probably, should be application Model
  attachments: { loading: boolean; files: UploadedFileDataObject[] };
};

type P = {
  viewer: ReduxUser;
  onRequestMentions: (value: string) => Promise<AutocompleteUser[]>;
  onSubmit: (values: CommentFormValues) => void;
  isLoading?: boolean;
  initialValues?: Partial<CommentFormValues>;
  avatar?: boolean;
};

// TODO: fix layout
// TODO: fix comment default state
const CommentForm = ({ viewer, onRequestMentions, onSubmit, isLoading, initialValues, avatar = true }: P) => {
  const { t } = useTranslation();
  const [form] = Form.useForm<CommentFormValues>();

  const handleOnRequestMentions = useRequestMentions(onRequestMentions);

  const formInitialValues: Partial<CommentFormValues> = {
    textContent: '',
    htmlContent: '',
    attachments: { loading: false, files: [] },
    ...initialValues,
  };

  return (
    <Form<CommentFormValues>
      form={form}
      initialValues={formInitialValues}
      onFinish={onSubmit}
      className={cn('comment-form', { 'comment-form--no-user': !avatar })}
    >
      <Form.Item<CommentFormValues> noStyle dependencies={['attachments']}>
        {({ getFieldsValue, setFieldsValue }) => {
          const { attachments } = getFieldsValue(true) as CommentFormValues;

          if (attachments.files.length > 0) {
            return (
              <div className="comment-form__attachments">
                <Files
                  attachments={attachments.files}
                  onRemove={(attachment) => {
                    setFieldsValue({
                      attachments: {
                        loading: attachments.loading,
                        files: attachments.files.filter((file) => file !== attachment),
                      },
                    });
                  }}
                  onClear={() => {
                    setFieldsValue({ attachments: { loading: attachments.loading, files: [] } });
                  }}
                />
              </div>
            );
          }

          return null;
        }}
      </Form.Item>

      {/* TODO: fix in edit comment style */}
      {avatar ? (
        <div className="comment-form__user">
          <Avatar size="default" photo={viewer.profile_photo?.url ?? images.default.userProfilePhoto} />
        </div>
      ) : null}

      <div className="comment-form__rich-text">
        <RichTextInput
          text={{ name: 'textContent' }}
          html={{
            name: 'htmlContent',
            label: 'Comment',
            placeholder: 'Write comment...',
            mentions: handleOnRequestMentions,
            collapsible: true,
            isFocusedInitially: true,
            rules: [
              {
                // eslint-disable-next-line @typescript-eslint/require-await -- we need return promise with async function
                async validator(_, value) {
                  const attachments = form.getFieldValue('attachments');

                  if ((!attachments.files || attachments.files.length === 0) && !value) {
                    throw new Error(t('Error: Post cannot be empty'));
                  }
                },
              },
            ],
            dependencies: ['attachments'],
          }}
        />
      </div>

      <div className="comment-form__actions">
        <Form.Item
          noStyle
          name="attachments"
          rules={[
            {
              message: '',
              validateTrigger: ['onSubmit'],
              // eslint-disable-next-line @typescript-eslint/require-await -- we need return promise with async function
              async validator(_, attachments) {
                if (attachments.loading) {
                  message.error(
                    'The attachment upload is still in progress. You can submit the post after the upload is finished.',
                  );
                  throw new Error(t('The attachment upload is still in progress.'));
                }
              },
            },
          ]}
        >
          <AttachmentWrapper className="comment-form__actions__attachments" />
        </Form.Item>

        <Button size="small" type="primary" loading={isLoading} htmlType="submit">
          Post
        </Button>
      </div>
    </Form>
  );
};

export default CommentForm;
