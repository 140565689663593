import { Space, Upload, type UploadProps } from 'antd';

import { type Rule } from 'antd/lib/form';
import { type RcFile, type UploadChangeParam, type UploadFile } from 'antd/lib/upload/interface';
import cn from 'classnames';
import { type UploadRequestOption } from 'rc-upload/lib/interface';
import { useTranslation } from 'react-i18next';
import { loadImage } from 'common/utils';
import { message, Progress, type Form, Row, Item, Image as ImageComponent, PicLineIcon, Title } from '../../';

const { Dragger } = Upload;

type UploadFileData<T> = UploadFile<T> & { image?: T };
export type ImageUploadedData<T> = UploadFileData<T> & { image: T };

type ImageInputSize = 'large' | 'medium' | 'small';

type ImageBaseType = {
  url?: string;
  filename?: string;
};

type ImageProperties = {
  width: number;
  height: number;
};

type UploadFunction<T> = (
  file: Blob | RcFile | string,
  onProgress: (event: { target: XMLHttpRequest; loaded: number; total: number }) => void,
) => Promise<T>;

type ValidationFileFunction = (file: Blob | RcFile) => boolean;
type ValidationImageFunction = (image: ImageProperties) => boolean;

type Validation = {
  key: string;
  message: string;
  isValidFile?: ValidationFileFunction;
  isValidImage?: ValidationImageFunction;
};

type UploadImageProperties<T extends ImageBaseType> = {
  validations: Validation[];
  uploadFile: UploadFunction<T>;
  id?: string;
  value?: UploadFileData<T>;
  size?: ImageInputSize;
  onChange?: (event: UploadChangeParam<UploadFile<T>>) => void;
};

type ItemP = React.ComponentProps<typeof Form.Item>;

type ImageInputProperties<T extends ImageBaseType> = {
  uploadFile: UploadFunction<T>;
  validations?: Validation[];
  item?: ItemP;
  type?: 'round';
  size?: ImageInputSize;
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const FILE_VALIDATION_ERROR: Validation = {
  key: 'unexpected_file_type',
  message: '{{fileName}} is not valid image.',
  isValidFile: (file) => file.type.startsWith('image/'),
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const UNEXPECTED_ERROR: Validation = {
  key: 'unexpected_error',
  message: 'Sorry, something went wrong.',
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const FAILED_UPLOAD: Validation = {
  key: 'network_failed_upload',
  message: 'Failed to upload',
};

const UploadImage = <T extends ImageBaseType>({
  validations,
  uploadFile,
  onChange,
  value,
  size,
  ...rest
}: UploadImageProperties<T>) => {
  const { t } = useTranslation();

  const handleChange = (info: UploadChangeParam<UploadFile<T>>) => {
    if (info.file.status === 'error') {
      const fileName = info.file.name ?? t('File');
      const customValidation = validations?.find((validation) => validation.key === info.file.error?.message);
      message.error(
        customValidation ? t(customValidation.message, { fileName }) : t(FAILED_UPLOAD.message, { fileName }),
      );
    }

    if (onChange) {
      onChange(info);
    }
  };

  const props: UploadProps<T> = {
    accept: 'image/*',
    listType: 'picture-card',
    multiple: false,
    showUploadList: false,
    maxCount: 1,
    async customRequest({ onError, onSuccess, onProgress, file }: UploadRequestOption<T>) {
      if (typeof file === 'string') {
        onError?.(new Error(UNEXPECTED_ERROR.key));
        return;
      }

      const failedFileValidation = validations.find((validation) =>
        validation.isValidFile ? !validation.isValidFile(file) : false,
      );

      if (failedFileValidation) {
        onError?.(new Error(failedFileValidation.key));
        return;
      }

      try {
        const dimensions: HTMLImageElement = await loadImage(file);
        const failedImageValidation = validations.find((validation) =>
          validation.isValidImage ? !validation.isValidImage(dimensions) : false,
        );

        if (failedImageValidation) {
          onError?.(new Error(failedImageValidation.key));
          return;
        }

        try {
          let target: XMLHttpRequest | undefined;
          const response = await uploadFile(file, (value) => {
            target = value.target;
            onProgress?.({ percent: Math.floor(100 * (value.loaded / value.total)), ...value });
          });

          onSuccess?.(response, target);
        } catch {
          onError?.(new Error(FAILED_UPLOAD.key));
        }
      } catch {
        onError?.(new Error(UNEXPECTED_ERROR.key));
      }
    },
    onChange: handleChange,
  };

  const renderValue = (value: UploadFileData<T>) => {
    if (typeof value.percent === 'number' && value.status === 'uploading') {
      return <Progress className="image-input__progress" percent={Math.min(value.percent, 99)} />;
    }

    return <ImageComponent src={value.image?.url ?? value.url ?? value.thumbUrl} preview={false} />;
  };

  return (
    <Dragger fileList={value ? [value] : []} {...rest} {...props} className="image-input">
      {value && value.status !== 'error' ? (
        renderValue(value)
      ) : (
        <Row item={{ className: 'image-input__empty' }}>
          <Space size="small" direction="vertical" className="image-input__empty__space">
            <PicLineIcon
              className={cn({
                'image-input__empty__icon--large': size === 'large',
                'image-input__empty__icon--medium': size === 'medium',
                'image-input__empty__icon--small': size === 'small',
              })}
            />
            {size === 'large' ? <Title level={5}>Add photo</Title> : null}
          </Space>
        </Row>
      )}
    </Dragger>
  );
};

const defaultValidations: Validation[] = [];

const ImageInput = <T extends ImageBaseType>({
  validations = defaultValidations,
  uploadFile,
  item,
  type,
  size,
}: ImageInputProperties<T>) => {
  const { t } = useTranslation();

  const allValidations: Validation[] = [FILE_VALIDATION_ERROR, FAILED_UPLOAD, UNEXPECTED_ERROR, ...validations];

  return (
    <Item
      rules={[
        {
          validateTrigger: 'onSubmit',
          // eslint-disable-next-line @typescript-eslint/require-await -- we need return promise with async function
          async validator(_, value?: UploadFileData<T>) {
            if (value?.status === 'uploading') {
              throw new Error(t('Please wait for image upload'));
            }
          },
        },
        ...allValidations.map(
          (validation): Rule => ({
            message: t(validation.message, { fileName: t('File') }),
            // eslint-disable-next-line @typescript-eslint/require-await -- we need return promise with async function
            async validator(_, value?: UploadFileData<T>) {
              if (value?.status === 'error' && value.error.message === validation.key) {
                throw value.error;
              }
            },
          }),
        ),
      ]}
      getValueFromEvent={({ file }: UploadChangeParam<UploadFile<T>>): UploadFileData<T> => {
        return {
          ...file,
          image: file.response,
        };
      }}
      {...item}
      className={cn(item?.className, 'image-input', {
        'image-input--round': type === 'round',
        'image-input--large': size === 'large',
        'image-input--small': size === 'small',
        'image-input--medium': size === 'medium',
      })}
    >
      <UploadImage<T> size={size} validations={allValidations} uploadFile={uploadFile} />
    </Item>
  );
};

export const transformImageForInit = <T extends ImageBaseType>(image: T): ImageUploadedData<T> => ({
  uid: `_GENERATED_${Date.now()}`,
  name: image.filename ?? 'GENERATED_FILENAME',
  image,
});

export default ImageInput;
