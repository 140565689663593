import { LoadingOutlined } from '@ant-design/icons';
import { type ReduxUser } from 'models';
import { routes } from 'routes';
import { routes as routesAdmin, generateAdminDashboardUrl } from 'common/admin-dashboard';
import { useAdminDashboardRedirect } from 'common/admin-dashboard/queries';
import { routes as routesEhr, generateEhrPortalUrl } from 'common/ehr-portal';
import { useEhrPortalRedirect } from 'common/ehr-portal/queries';
import variables from 'common/styles/variables.json';
import { images } from 'common/utils';
import { isGamificationEnabled } from 'common/validators';
import { ChangelogModal, useChangelogVisibilityOptions } from 'features/changelog';
import { OverallPoints } from 'features/gamification';
import { type NodeUserDataObject } from 'models/service';
import { canViewAdmin, canViewAdminDashboard, canViewEhrPortal, canViewWhiteLabel } from 'permissions';
import { Space, Badge, Avatar, Dropdown, message, MenuLinkItem, Link } from '../../..';
import {
  NotificationIcon,
  ProfileIcon,
  CommunityIcon,
  WhiteLabelIcon,
  AdminConsoleIcon,
  EHRIcon,
  SettingsIcon,
  LogOutIcon,
  HornLineIcon,
  QuestionLineIcon,
} from '../../../Icons';

type P = {
  viewer: ReduxUser;
  nodeViewer?: NodeUserDataObject;
  badges: {
    total: number;
  };
  onLogOut: () => Promise<void>;
};

const UserMenu = ({ viewer, nodeViewer, badges: { total = 0 }, onLogOut }: P) => {
  const { isLoading: isRedirectingToAdminDashboard, mutate: redirectToAdminDashboard } = useAdminDashboardRedirect();
  const { isLoading: isRedirectingToEhrPortal, mutate: redirectToEhrPortal } = useEhrPortalRedirect();
  const [isOpened, setIsOpened] = useChangelogVisibilityOptions();

  const items = [
    {
      key: 'profile',
      label: <MenuLinkItem to={routes.user.profile} text="Profile" />,
      icon: <ProfileIcon />,
      visible: true,
    },
    {
      key: 'admin',
      label: <MenuLinkItem to={routes.admin.index} text="Manage communities" />,
      icon: <CommunityIcon />,
      visible: canViewAdmin({ viewer }),
    },
    {
      key: 'whiteLabel',
      label: <MenuLinkItem to={routes.admin.whiteLabel} text="White Label" />,
      icon: <WhiteLabelIcon />,
      visible: canViewWhiteLabel({ viewer }),
    },
    {
      key: 'adminConsole',
      label: (
        <MenuLinkItem
          to={{ pathname: generateAdminDashboardUrl(routesAdmin.index) }}
          target="_blank"
          text="Admin console"
          onClick={() => {
            message.info('Redirecting to Admin console');
            redirectToAdminDashboard({ referrer: routesAdmin.index });
          }}
        />
      ),
      icon: isRedirectingToAdminDashboard ? <LoadingOutlined /> : <AdminConsoleIcon />,
      visible: canViewAdminDashboard({ viewer }),
    },
    {
      key: 'ehrPortal',
      label: (
        <MenuLinkItem
          to={{ pathname: generateEhrPortalUrl(routesEhr.index) }}
          target="_blank"
          text="EHR Portal"
          onClick={() => {
            message.info('Redirecting to EHR Portal');
            redirectToEhrPortal({ referrer: routesEhr.index });
          }}
        />
      ),
      icon: isRedirectingToEhrPortal ? <LoadingOutlined /> : <EHRIcon />,
      visible: canViewEhrPortal({ viewer, nodeViewer }),
    },
    {
      key: 'releaseNotes',
      // TODO this hack with `to=""` is not nice and we should find other solution maybe?
      label: (
        <MenuLinkItem
          to=""
          text="Release notes"
          onClick={() => {
            setIsOpened(true);
          }}
        />
      ),
      icon: <HornLineIcon />,
      visible: true,
    },
    {
      key: 'settings',
      label: <MenuLinkItem to={routes.user.settings} text="Settings" />,
      icon: <SettingsIcon />,
      visible: true,
    },
    {
      key: 'help',
      label: <MenuLinkItem to={routes.user.help} text="Help" />,
      icon: <QuestionLineIcon />,
      visible: true,
    },
    {
      key: 'logout',
      label: <MenuLinkItem to="" text="Log out" onClick={onLogOut} />,
      icon: <LogOutIcon />,
      visible: true,
    },
  ]
    .filter((item) => item.visible)
    .map(({ visible, ...item }) => ({ ...item, className: 'user-menu__dropdown__item' }));

  return (
    <Space className="user-menu" size={variables.spaceMd.value}>
      {isGamificationEnabled() ? <OverallPoints userId={viewer.id} /> : null}
      <Link className="user-menu__notifications" to={routes.notifications}>
        <Badge className="user-menu__notifications__badge" overflowCount={99} count={Math.max(total, 0)} color="red">
          <NotificationIcon className="user-menu__notifications__icon" />
        </Badge>
      </Link>
      <Dropdown className="user-menu__dropdown" placement="bottomRight" trigger={['click']} menu={{ items }}>
        <Avatar photo={viewer.profile_photo?.url ?? images.default.userProfilePhoto} />
      </Dropdown>
      <ChangelogModal
        isOpened={isOpened}
        onClose={() => {
          setIsOpened(false);
        }}
      />
    </Space>
  );
};

export default UserMenu;
