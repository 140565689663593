import {
  type Entity,
  type EventEmailInvitee,
  type RegisteredEventInvitee,
  InviteType,
  type EventContentObject,
  type Image,
  type CommunityListItem,
  type EventType,
  type EventPrivacy,
  Event,
  type ReduxUser,
} from 'models';
import { type Moment } from 'moment';
import { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { containsEmoji } from 'common/validators';
import {
  TextInput,
  RichTextInput,
  SelectInput,
  ImageInputDeprecated,
  AttachmentsInput,
  Form,
  CardTabs,
  CardTab,
  ModalFormActions,
  type UploadChangeParam,
  Title,
  generateAttachmentsInputInitialValue,
  type AttahcmentsInputUploadFile,
} from 'ui';

import { type DeprecatedEventProgramProperties } from '../../../../types';
import EventInviteesSelect from '../../../EventInviteesSelect';

import EventCommunity from '../EventCommunity';
import { mapInviteTypeToRole, useEventInvitees, EventInviteesPicker } from '../EventInviteesPicker';
import EventPublish from '../EventPublish';
import EventTime from '../EventTime';
import EventChannelsOptions from './EventChannelsOptions';
import EventInviteesExceededLimit from './EventInviteesExceededLimit';

// TODO this still is not correct but it is close
type EventFiles =
  | UploadChangeParam<AttahcmentsInputUploadFile<{ id: string }>>
  | { fileList: UploadChangeParam<AttahcmentsInputUploadFile<{ id: string }>>['fileList'] };

export type DefaultEventData = {
  formValues: DefaultEventFormValues;
  eventType: Exclude<EventType, 'telehealth_visit'>;
};

export type DefaultEventFormValues = {
  programId?: string;
  programTopicId?: string;
  publishAt?: Moment;
  startTime: Moment;
  endTime: Moment;
  duration: number;
  communityId?: string;
  organizationId?: string;
  coverPhoto?: Image;
  name: string;
  htmlContent?: string;
  textContent?: string;
  viewPermission: EventPrivacy;
  invitees?: RegisteredEventInvitee[];
  moderators: RegisteredEventInvitee[];
  presenters: RegisteredEventInvitee[];
  emailInvitees?: EventEmailInvitee[];
  emailPresenters: EventEmailInvitee[];
  emailModerators: EventEmailInvitee[];
  eventsFiles?: EventFiles;
  id?: string;
  tags?: string[];
  showInChannels?: boolean;
  featuredInChannels?: boolean;
};

const getInitialValues = ({
  eventContentObject,
  program,
  communityId,
  isProgram,
  isDuplicating,
  communities,
}: {
  eventContentObject?: EventContentObject;
  program?: DeprecatedEventProgramProperties;
  communityId?: string;
  isProgram: boolean;
  isDuplicating: boolean;
  communities: CommunityListItem[];
}): Partial<DefaultEventFormValues> => {
  const event = eventContentObject?.event;
  const publishAt = Event.generatePublishAt({ isDuplicating, isProgram, publishAt: eventContentObject?.publishAt });
  const startTime = Event.generateStartDate({ startTime: event?.startTime, isDuplicating, publishAt });
  const duration = event?.duration ?? 30;
  const endTime = Event.getEndDate({ startTime, duration, endTime: event?.endTime, isDuplicating });

  const initialCommunityId =
    eventContentObject?.postInCommunities.map((community: Entity) => community.id)[0] ?? communityId;

  return {
    programId: eventContentObject?.program?.id ?? program?.id,
    programTopicId: eventContentObject?.programTopicId,
    communityId: initialCommunityId,
    organizationId: communities.find((community) => community.id === initialCommunityId)?.organization.id,
    coverPhoto: event?.coverPhoto ?? undefined,
    duration,
    startTime,
    publishAt,
    endTime,
    eventsFiles: generateAttachmentsInputInitialValue(event?.eventsFiles),
    htmlContent: event?.htmlContent ?? '',
    id: event?.id ?? '',
    name: event?.name ?? '',
    textContent: event?.textContent ?? '',
    viewPermission: program ? 'public' : event?.viewPermission ?? 'private',
    invitees:
      event?.invitees.filter(
        (invitee: RegisteredEventInvitee) => invitee.eventRole === mapInviteTypeToRole(InviteType.invitees),
      ) ?? [],
    moderators:
      event?.invitees.filter(
        (invitee: RegisteredEventInvitee) => invitee.eventRole === mapInviteTypeToRole(InviteType.moderators),
      ) ?? [],
    presenters:
      event?.invitees.filter(
        (invitee: RegisteredEventInvitee) => invitee.eventRole === mapInviteTypeToRole(InviteType.presenters),
      ) ?? [],
    emailInvitees:
      event?.emailInvitees.filter(
        (invitee: EventEmailInvitee) => invitee.eventRole === mapInviteTypeToRole(InviteType.invitees),
      ) ?? [],
    emailModerators:
      event?.emailInvitees.filter(
        (invitee: EventEmailInvitee) => invitee.eventRole === mapInviteTypeToRole(InviteType.moderators),
      ) ?? [],
    emailPresenters:
      event?.emailInvitees.filter(
        (invitee: EventEmailInvitee) => invitee.eventRole === mapInviteTypeToRole(InviteType.presenters),
      ) ?? [],
    tags: event?.tags?.map((tagItem) => tagItem.id) ?? [],
    showInChannels: event?.showInChannels ?? false,
    featuredInChannels: event?.featuredInChannels ?? false,
  };
};

type P = {
  eventType: Exclude<EventType, 'telehealth_visit'>;
  mode: 'create' | 'duplicate' | 'edit';
  isLoading: boolean;
  communities: CommunityListItem[];
  inviteType: InviteType;
  onSubmit: (formData: DefaultEventData, event?: Event) => Promise<void> | void;
  onInviteesTypeChanged: (type: InviteType) => void;
  communityId?: string;
  program?: DeprecatedEventProgramProperties;
  eventContentObject?: EventContentObject;
  viewer: ReduxUser;
};

const DefaultEventForm = ({
  isLoading,
  onSubmit,
  eventContentObject,
  onInviteesTypeChanged,
  inviteType,
  communities,
  program,
  communityId,
  eventType,
  mode,
  viewer,
}: P) => {
  const isProgram = Boolean(program);
  const event = eventContentObject?.event;
  const isDuplicating = mode === 'duplicate';

  const { t } = useTranslation();
  const [form] = Form.useForm<DefaultEventFormValues>();

  const initialValues = useMemo(
    () => getInitialValues({ eventContentObject, program, communityId, isProgram, isDuplicating, communities }),
    [eventContentObject, program, communityId, isProgram, isDuplicating, communities],
  );

  const [minimumStart, setMinimumStart] = useState<Moment>(
    Event.generateStartDate({ publishAt: isProgram ? initialValues.publishAt : undefined }),
  );

  const { resetSelectedUsers, onInvite, onShowInvitees } = useEventInvitees({
    skipPostInCommunityValidation: isProgram,
    form,
    onInviteesTypeChanged,
  });

  const onUpdatePublish = (publishAt: Moment) => {
    const start: Moment = form.getFieldValue('startTime');
    const duration: number = form.getFieldValue('duration');
    const minimum = Event.generateStartDate({ publishAt });

    setMinimumStart(minimum);
    if (start.isBefore(minimum)) {
      form.setFieldsValue({
        startTime: minimum,
        endTime: Event.getEndDate({ startTime: minimum, duration }),
      });
    }
  };

  const resetChannelsOptions = () => {
    form.setFieldsValue({ showInChannels: false, featuredInChannels: false, tags: [] });
  };

  const submitTitle = () => {
    switch (mode) {
      case 'duplicate':
        return 'Duplicate Event';
      case 'create':
        return 'Create Event';
      case 'edit':
        return 'Edit Event';
    }
  };

  const onFinish = async (formValues: DefaultEventFormValues) => onSubmit({ formValues, eventType }, event);

  return (
    <Form form={form} initialValues={initialValues} onFinish={onFinish} name="event-form">
      {inviteType ? (
        <Form.Item noStyle dependencies={['communityId']}>
          {() => {
            const communityId: string | undefined = form.getFieldValue('communityId');

            return (
              <EventInviteesPicker
                form={form}
                communityId={communityId}
                postInProgram={program}
                inviteType={inviteType}
                onInvite={onInvite}
              />
            );
          }}
        </Form.Item>
      ) : (
        <>
          <Title level={4}>Event details</Title>
          {program ? (
            <>
              <TextInput item={{ name: 'programId', hidden: true }} />
              <SelectInput<{
                id: string;
                name: string;
                description: string;
              }>
                item={{
                  name: 'programTopicId',
                  label: 'Program module',
                  rules: [
                    {
                      required: true,
                      message: t('Please select the module'),
                    },
                  ],
                  'data-test-id': 'select_course-topic',
                }}
                input={{
                  placeholder: t('Please select the module the event belongs to'),
                  options: program.topics,
                  getOptionLabel: (option) => option.name,
                  getOptionValue: (option) => option.id,
                }}
              />
              <EventPublish
                item={{
                  name: 'publishAt',
                  label: 'Publish date',
                  rules: [
                    {
                      required: true,
                    },
                    () => ({
                      async validator(rule: undefined, value?: Moment) {
                        if (value) {
                          if (value.isBefore(program.start_time)) {
                            throw t('Error: Post publish date must be set after Program start time');
                          }

                          if (value.isAfter(program.end_time)) {
                            throw t('Error: Post publish date must be set before Program end time');
                          }
                        }

                        // eslint-disable-next-line unicorn/no-useless-promise-resolve-reject
                        return Promise.resolve();
                      },
                    }),
                  ],
                }}
                input={{
                  onChange: onUpdatePublish,
                }}
              />
            </>
          ) : (
            <EventCommunity
              communities={communities}
              item={{
                label: 'Select community to invite',
                rules: [{ required: true }],
              }}
              input={{
                onChange(communityId) {
                  resetSelectedUsers?.();

                  // TODO find better solutuon how to reset tags on change of Organization
                  const newOrganizationId = communities.find((community) => community.id === communityId)?.organization
                    .id;
                  const { organizationId: currentOrganizationId } = form.getFieldsValue(['organizationId']);

                  if (currentOrganizationId === newOrganizationId) {
                    form.setFieldsValue({ organizationId: newOrganizationId });
                  } else {
                    form.setFieldsValue({
                      organizationId: newOrganizationId,
                    });
                    resetChannelsOptions();
                  }
                },
              }}
            />
          )}
          <EventTime
            mode={mode}
            minimum={minimumStart}
            form={form}
            start={{ name: 'startTime', label: t('Starts on') }}
            duration={{ name: 'duration', label: t('For') }}
            end={{ name: 'endTime' }}
            /* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
            /* eslint-disable react/jsx-no-leaked-render */
            disabledTime={(event?.isInProgress || event?.isFinished) && !isDuplicating}
            disabledDuration={event?.isFinished && !isDuplicating}
            /* eslint-enable @typescript-eslint/prefer-nullish-coalescing */
            /* eslint-enable react/jsx-no-leaked-render */
          />
          <TextInput
            item={{
              name: 'name',
              label: eventType === 'webinar' ? 'Webinar / Webcast name' : 'Video conference name',
              rules: [
                {
                  required: true,
                  message: t('Please provide event title'),
                  whitespace: true,
                },
                {
                  validator: async (_, value) => (containsEmoji(value) ? Promise.reject() : Promise.resolve()),
                  message: t(`Event name can't contain emojis`),
                },
              ],
            }}
          />
          <Title level={4}>{isProgram ? 'Invitations' : 'Who do you want to invite?'}</Title>
          {!isProgram && (
            <CardTabs
              name="viewPermission"
              tabs={[
                {
                  id: 'select-community-members',
                  key: 'private',
                  content: (
                    <CardTab title="Select community members" subtitle="Only invited community members can join." />
                  ),
                },
                {
                  id: 'all-community-members',
                  key: 'public',
                  content: <CardTab title="All community members" subtitle="You will invite all community members." />,
                },
              ]}
              onSelect={() => {
                resetChannelsOptions();
              }}
            />
          )}
          <EventInviteesSelect
            name="moderators"
            nameEmail="emailModerators"
            label="Admins / Hosts"
            buttonText="Invite"
            onPress={onShowInvitees(InviteType.moderators)}
            id="btn_invite-admins"
            disabled={mode === 'edit' && event?.isFinished}
          />
          <EventInviteesSelect
            name="presenters"
            nameEmail="emailPresenters"
            label="Presenters"
            buttonText="Invite"
            onPress={onShowInvitees(InviteType.presenters)}
            id="btn_invite-presenters"
            disabled={mode === 'edit' && event?.isFinished}
            inviteOptions={
              mode === 'edit' && event?.id
                ? {
                    eventId: event.id,
                    kind: 'event_presenter_invitation',
                    successMessage: 'Link for presenter copied to clipboard',
                  }
                : undefined
            }
          />
          <Form.Item noStyle dependencies={['viewPermission']}>
            {({ getFieldValue }) => {
              const viewPermission: EventPrivacy = getFieldValue('viewPermission');

              return (
                <EventInviteesSelect
                  name="invitees"
                  nameEmail="emailInvitees"
                  label="Attendees"
                  buttonText="Invite"
                  onPress={onShowInvitees(InviteType.invitees)}
                  id="btn_invite-attendees"
                  disabled={viewPermission === 'public' || (mode === 'edit' && event?.isFinished)}
                  inviteOptions={
                    mode === 'edit' && event?.id
                      ? {
                          eventId: event.id,
                          kind: 'event_attendee_invitation',
                          successMessage: 'Link for Attendee copied to clipboard',
                        }
                      : undefined
                  }
                />
              );
            }}
          </Form.Item>

          {isProgram ? null : (
            <EventChannelsOptions<DefaultEventFormValues> communities={communities} viewer={viewer} />
          )}

          <EventInviteesExceededLimit form={form} event={event} communities={communities} />
          <Title level={4}>Additional details</Title>
          <RichTextInput
            text={{ name: 'textContent' }}
            html={{
              name: 'htmlContent',
              label: 'Description (Optional)',
              maxWidth: '630px',
              'data-test-id': 'event_description',
            }}
          />
          <ImageInputDeprecated
            item={{ name: 'coverPhoto', label: 'Event photo' }}
            aspectRatio={{ width: 965, height: 300 }}
          />
          {mode === 'edit' ? <AttachmentsInput item={{ label: 'Slides', name: 'eventsFiles' }} /> : null}
          <ModalFormActions
            submit={{
              loading: isLoading,
              children: submitTitle(),
              id: 'btn_schedule-event',
            }}
          />
        </>
      )}
    </Form>
  );
};

export default DefaultEventForm;
